:root {
  --primary-color: #b08038;
  --swiper-theme-color: var(--primary-color) !important;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navbar-box-shadow {
  /* box-shadow: 0 2px 10px 0 #f5f5f5;
  box-shadow: 0 4px 0px 0 #f6f6f6; */
}
/* .navbar {
  background-image: url(./assets/images/waves_b.svg);
  background-repeat: no-repeat;
  background-position: center;
} */
.navbar-item {
  font-weight: 500;
}

.footerImgStyle {
  width: 120px !important;
}

/* For Section margin-top */
section {
  scroll-margin-top: 4rem;
}

.section-heading {
  display: inline-block;
  position: relative;
}
.section-heading::after {
  content: '';
  height: 4px;
  width: 33%;
  background-color: var(--primary-color);
  position: absolute;
  left: 0%;
  bottom: -8px;
}

.section-heading::before {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  transform: translateX(10%);
  top: -30%;
  background-image: url('./assets/images/point.svg');
  z-index: -999;
}

/* General utility class */
.center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.back-to-top-button button {
  border-radius: 999px;
}

.footer {
  padding: 2rem 1.5rem;
  background-image: url('./assets/images/footer_bg_.jpg');
  background-position: center;
  background-size: cover;
  color: hsl(0, 0%, 96%);
}
.footer-top {
  padding-bottom: 1.5rem;
}
.footer-bottom {
  padding-top: 1.5rem;
}
.footer .divider {
  border-top: 1px solid gray;
}

.footer-title-line {
  display: block;
  height: 2px;
  width: 40px;
  background-color: var(--primary-color);
}

@media screen and (max-width: 768px) {
  .cookieConsent {
    justify-content: center !important;
  }
  .reverse-columns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .footerImgStyle {
    width: 80px !important;
  }
}

/* Used in Team section For CARDS.... */
.team-image {
  object-fit: cover;
  filter: grayscale(100%);
}

.client-image {
  object-fit: contain;
  height: 80px;
}

.gallery-image {
  object-fit: contain;
  height: 300px;
}

.card-class {
  display: flex;
  flex-grow: 1;
  /* 
  used flex-grow: 1 instead of bottom min-width
  min-width: 100%; 
  */
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch !important;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.card-class:hover {
  -ms-transform: scale(1.01);
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.card-class > .card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.card-class > .card-content > .button {
  align-self: flex-end;
  margin-top: auto;
}

#home {
  background-image: url(./assets/images/waves_b.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 250vw;
}

@media screen and (max-width: 1023px) {
  .is-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  #home {
    background-size: 400vw;
    background-position: left bottom;
  }
}
